<template>
	<layout-div>
		<div class="card">
			<form>
				<div class="card-header">
					<button @click="handleSave()"
							:disabled="isSaving"
							type="button"
							class="btn btn-info mt-3 float-left">
						儲存
					</button>

					<router-link class="btn btn-default mt-3 float-right"
								to="/admin/Document/index">
						返回列表
					</router-link>
				</div>
				<div class="card-body">
					<div class="form-horizontal col-md-8">
						<div class="form-group row">
							<label htmlFor="id" class="col-sm-2 col-form-label">編號</label>
							<div class="col-sm-10">
								<input v-model="model.id"
									type="text"
									class="form-control"
									id="id"
									name="id"
								readonly />
							</div>
						</div>
						<div class="form-group row">
							<label htmlFor="housecode" class="col-sm-2 col-form-label">案場</label>
							<div class="col-sm-10">
								<input v-model="model.housecode"
									type="text"
									class="form-control"
									id="housecode"
									name="housecode"
								/>
							</div>
						</div>
						<div class="form-group row">
							<label htmlFor="filename" class="col-sm-2 col-form-label">檔案名稱</label>
							<div class="col-sm-10">
								<input v-model="model.filename"
									type="text"
									class="form-control"
									id="filename"
									name="filename"
								/>
							</div>
						</div>
						<div class="form-group row">
							<label htmlFor="ext" class="col-sm-2 col-form-label">副檔名</label>
							<div class="col-sm-10">
								<input v-model="model.ext"
									type="text"
									class="form-control"
									id="ext"
									name="ext"
								/>
							</div>
						</div>
						<div class="form-group row">
							<label htmlFor="realfile" class="col-sm-2 col-form-label">真實檔名</label>
							<div class="col-sm-10">
								<input v-model="model.realfile"
									type="text"
									class="form-control"
									id="realfile"
									name="realfile"
								/>
							</div>
						</div>
						<div class="form-group row">
							<label htmlFor="printCount" class="col-sm-2 col-form-label">預設列印份數</label>
							<div class="col-sm-10">
								<input v-model="model.printCount"
									type="text"
									class="form-control"
									id="printCount"
									name="printCount"
								/>
							</div>
						</div>
						<div class="form-group row">
							<label htmlFor="editor" class="col-sm-2 col-form-label">最後上傳人員</label>
							<div class="col-sm-10">
								<input v-model="model.editor"
									type="text"
									class="form-control"
									id="editor"
									name="editor"
								/>
							</div>
						</div>
						<div class="form-group row">
							<label htmlFor="update_time" class="col-sm-2 col-form-label">最後更改日期</label>
							<div class="col-sm-10">
								<input v-model="model.update_time"
									type="date"
									class="form-control"
									id="update_time"
									name="update_time"
								/>
							</div>
						</div>

					</div>
				</div>
			</form>
		</div>
	</layout-div>
</template>

<script>
	import LayoutDiv from '@/components/LayoutDiv.vue';
	import { getDocument, postDocument } from '@/api/Document.js'

	export default {
		name: 'DocumentEdit',
		components: {
			LayoutDiv,
		},
		data() {
			return {
				model: {
					id: '',
					housecode: '',
					filename: '',
					ext: '',
					realfile: '',
					printCount: '',
					editor: '',
					update_time: '',

				},
				isSaving: false,
			};
		},
		created() {
			const id = this.$route.params.id;

			getDocument(id).then(rep => {
				this.model = rep.data;
			});

		},
		methods: {
			handleSave() {
				if (this.isSaving == true) return;

				this.isSaving = true;
				postDocument(this.model).then(rep => {

					this.isSaving = false;
					return rep;
				})
					.catch(error => {
						this.isSaving = false;
						return error
					});
			},
		},
	};
</script>